<template>

  <div class="mg">
    <div class="top">
      <img class="img1" src="@/assets/images/loop-burn-sports.jpg" alt="" />
      <div class="video11">
        <video controls="controls" autoplay="autoplay" loop="loop"  muted="muted"
         width="1200px" height="700px"
         src="@/assets/images/pai3.01.mp4" alt="视频1" />
      </div>
      <!-- <div class="logopai">
        <div class="text-logo">
          <div>
            <h1>公司介绍</h1>
            成立于2019年，是“互联网+”的体育用品品牌，主要业务为体育装备及运动分析软件和线下培训营与运动康复部门。
          </div>
          <div>
            <h1>Logo寓意</h1>
            派燃烧的中文logo由数字“8”和箭头构成。英文logo由Loop(循环)和Burn(燃烧)组成，译为“派燃烧”。寓意为
            <span>运动无极限，燃烧自我，向上而生。</span>
          </div>
        </div>
        <div>
          <img src="@/assets/images/logopai.png" alt="" />
        </div>
      </div> -->
    
      <h2>品牌文化</h2>
      <img src="@/assets/images/jianjie.jpg" alt="" />
    </div>
    <div class="mid">
      <div>
        <img src="@/assets/images/mission.jpg" alt="" />
      </div>
      <div>
        <img src="@/assets/images/sense-of-worth.jpg" alt="" />
      </div>
      <div>
        <img src="@/assets/images/vision.jpg" alt="" />
      </div>
    </div>
    <!-- <div class="speed"></div> -->
    <div class="bottom-container">
      <h2>品牌代言人</h2>
      <div class="bottom">
        <img src="@/assets/images/moyouxue.jpg" alt="" />
        <!-- <img src="@/assets/images/jianghengnan.jpg" alt="" /> -->
      </div>
    </div>
    <div class="power">
      <img  src="@/assets/images/sports-power.jpg" alt="" />
    </div>
  </div>

</template>

<script scoped>
export default {
  name: "CompanyProfile",
};

</script>

<style scoped>

h2 {
  margin-top: 30px;
}
.top {
  width: 100%;
}
.top img {
  width: 100%;
}
.img1 {
  height: 400px;
}
.mid {
  width: 100%;
  display: flex;
}
.mid div {
  flex: 1;
}
.mid div img {
  width: 100%;
}
.speed {
  width: 100%;
  height: 300px;
  background: url(../../../assets/images/paiDomain.jpg) center;
}
.bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bottom img {
  width: 60%;
}
.bottom img:nth-child(1) {
  margin-bottom: 60px;
}
.logopai {
  width: 100%;
  display: flex;
}
.logopai div {
  flex: 1;
}
.logopai div img {
  width: 100%;
}
.text-logo {
  display: flex;
  flex-direction: column;
}
.text-logo div {
  flex: 1;
  padding: 20px;
}
.text-logo span {
  color: red;
}
.power img {
  width: 100%;
  /* margin-top: 100px; */
}

</style>